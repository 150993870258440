import React,{useState,useCallback} from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import CookieBanner from '../CookieBanner';

const Layout = ({ children, footerProps, headerPosition }) => {
  const [,setCookieState] = useState(false);

  const acceptCookieHandler = useCallback(() => {
    Cookies.set('permission-cookies', 'perm-granted', { secure: true }, { expires: 150 });
    setCookieState(true);
  }, []);
    
  return (
    <>
      <Header position={headerPosition} />
      {children}
      {!Cookies.get('permission-cookies') && <CookieBanner acceptHandler={acceptCookieHandler}/>}
      <Footer
        showContactSection={footerProps.showContactSection}
        showPortfolioSection={footerProps.showPortfolioSection}
      />
    </>
  )
};

Layout.propTypes = {
  footerProps: PropTypes.shape({
    showPortfolioSection: PropTypes.bool.isRequired,
    showContactSection: PropTypes.bool.isRequired,
  }),
};

Layout.defaultProps = {
  footerProps: {
    showPortfolioSection: true,
    showContactSection: true,
  },
};

export default Layout;
