import React from 'react';
import 'react-multi-carousel/lib/styles.css';

import challengeOne from '../../assets/images/gamerize/challenge-1.png';
import challengeTwo from '../../assets/images/gamerize/challenge-2.png';
import characters from '../../assets/images/gamerize/characters.png';
import GamerizeOne from '../../assets/images/gamerize/gamerize-1.png';
import GamerizeTwo from '../../assets/images/gamerize/gamerize-2.png';
import GamerizeThree from '../../assets/images/gamerize/gamerize-3.png';
import GamerizeFour from '../../assets/images/gamerize/gamerize-4.png';
import tinLogo from '../../assets/images/gamerize/tinLogo.png';
import gamerize from '../../assets/images/gamerize/gamerize.png';

import bookmark from '../../assets/images/gamerize/gamerize-bookmark.png';

class Gamerize extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <section>
          <img className=" gamerize-pic" src={gamerize} alt="banner" />
        </section>
        <section className="container gamerize-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12 ">
              <div className="mini-heading">Case Study - Game Development</div>
              <div className="heading-one-g">Gamerize</div>
              <div className="heading-two-g">Dictionary</div>
              <div className="row para-mini-g pt-0">
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeOne} alt="" />
                  <div className="subheading">The Challenge</div>
                  <p className="desc">
                    The AAS Press based out of Japan was looking for an experienced partner to
                    develop an elaborate language learning game
                  </p>
                </div>
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeTwo} alt="" />
                  <div className="subheading">The Outcome</div>
                  <p className="desc">
                    Tintash proposed and executed upon a project plan to carry this vision to the
                    pilot stage in several Japanese school and beyond
                  </p>
                </div>
              </div>

              <section className="container gamerize-section-portfolio">
                <div className="row py-md-6 mx-0">
                  <p className="description">
                    <div className="subheading-big">The Project</div>
                    <span>
                      The Japan-based AAS Press engaged Tintash for a key language learning game.
                    </span>
                    <br />
                    When Tintash was contacted by the AAS Press of Japan, we knew there was
                    potentially an exciting opportunity ahead. We had not interfaced with the
                    Japanese market till this point but were keen to learn more about it as part of
                    our ambition for global excellence in product development.
                  </p>
                </div>
              </section>

              <p className="description">
                The AAS Press shared a vision document and related sketches of a series of
                mini-games embedded within a larger city-building game. Progress in this game was
                meant to be the outcome of improvement in language skills mechanics. There were thus
                game components as well as a web-based portal so that teachers and administrators
                could use this setup to assign language learning quests to students and review
                progress. Tintash submitted a proposal on the team required, the process to follow
                and estimates on costs and timelines.
              </p>
            </div>
          </div>
        </section>
        <section className="container ">
          <div className="picture-banner">
            <img className="mw-100" src={GamerizeOne} alt="gamerize" />
          </div>
          <div className="img-title">Gamerize Dictionary - Character Customizations</div>
        </section>
        <section className="container gamerize-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
                <div className="subheading-big">The Process</div>
                Once selected by AAS Press for this opportunity, Tintash began by working on some
                concept art. There were avatars, maps and buildings and it was important to get the
                look and feel just right given considerations of age and culture of the target
                market. We first worked on the riskiest but most pivotal features. Speech
                recognition based practice was one such feature. When it was found that the native
                device APIs for speech recognition were not effective in the target market, Tintash
                recommended and implemented the service provided by Soapbox Labs.
              </p>
              <br />
              <p className="description">
                It was key to get a basic game loop in place within about 6 months so piloting could
                begin. A map structure was put in place where you could access the quests through
                mini-games while watching your kingdom grow. There was a content database for
                scalability and compatibility with the constraints of the Japanese language. There
                was a user portal where Japanese-English language pairs could be selected and
                assigned by teachers for practice through ‘quests’. This kept things true to the
                original vision of building out a platform with interchange-able content that could
                ultimately be used for any form of language learning.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-banner">
            <img className="characters" src={characters} alt="gamerize" />
          </div>
          <div className="img-title">Gamerize Dictionary - Character Customizations</div>
        </section>
        <section className="container gamerize-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
                The process was iterative since much needed to be figured out and improved upon.
                Weekly calls with the AAS Press team helped with relevant discussions and
                clarifications. An active slack channel meant that we could always get quick
                feedback on shared mechanics and visuals. The project management system at the
                Tintash end and daily internal standups ensured that a team of artists, designers,
                engineers and testers that varied from 5 to 12 as needed had all its documentation
                in one place and daily tasks were aligned towards the common goal.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-banner">
            <img className="mw-100" src={GamerizeTwo} alt="gamerize" />
          </div>
          <div className="img-title">Gamerize Dictionary - Customize Avatar Store</div>
        </section>
        <section className="container gamerize-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
                Once we had a stable and testable game loop in place, the product was in a position
                to be piloted. As students in Japan tinkered with it, we started getting immediate
                feedback on which mini-games needed enhancement, how the game economy and enemy
                ‘strengths’ needed to be tweaked and what kind of glitches might arise at the
                teacher and student end during live use of the system. The team spent the next few
                months debugging for individual user accounts to makes the system more robust. In
                tandem, new features were constantly added from the perspective of making the
                gameplay more engaging and also increasing the educational rigor, from dashboards
                with precise analytics to live people on the map scene. More subtle elements such as
                lighting and water effects were continuously added to support the vision of really
                making this a magical experience in acquiring the English language.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-banner">
            <img className="mw-100" src={GamerizeThree} alt="gamerize" />
          </div>
          <div className="img-title">Gamerize Dictionary - The In-Game Screen</div>
        </section>
        <section className="container gamerize-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
                A year later, Tintash and AAS Press share a combined vision for making this game
                even bigger and better and taking it well beyond the Japanese market. Tintash
                continues to focus on scalability and enhancements as the user feedback pours in. We
                are thrilled at the opportunity to contribute to the global pursuit of learning
                facilitated by technology and that remains central to our mission.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-banner">
            <img className="mw-100" src={GamerizeFour} alt="gamerize" />
          </div>
          <div className="img-title">Gamerize Dictionary - The In-Game Screen</div>
        </section>

        <section className="container last-section-g">
          <div className="row">
            <div className="col-12">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{marginTop: '-16px'}}
              >
                <img src={bookmark} alt="bm" />
              </div>
              {/* <BookmarkIcon fontSize="large" style={bookmark} /> */}
              <div className="last-section-content">
                <p className="desc-text">
                  <span className="bold">
                    The Japan-based AAS Press engaged Tintash for a key language learning game.
                  </span>
                  <br />
                  When Tintash was contacted by the AAS Press of Japan, we knew there was
                  potentially an exciting opportunity ahead. We had not interfaced with the Japanese
                  market till this point but were keen to learn more about it as part of our
                  ambition for global excellence in product development.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container tintash-sign">
          <div className="row">
            <div className="col-12">
              <div className="tin-text"> Created and Developed by</div>
              <div className="tin-img">
                {' '}
                <img src={tinLogo} alt="bm" />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Gamerize;
